<template>
  <div>
    <!--begin::Finance-->
    <div class="card card-custom gutter-b">
      <div class="card-header pt-3 pb-3">
        <div class="card-title">
          <h3 class="card-label">Account Details</h3>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_account_details_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-data-table
                :headers="acc_headers"
                :items="accounts_list"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.Name }}</td>
                    <td>{{ row.item.AcctNum }}</td>
                    <td>{{ row.item.AccountType }}</td>
                    <td>{{ row.item.CurrentBalance }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </form>
      </div>
    </div>
    <!--end::Finance-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "financeList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      accounts_list: [],
      acc_headers: [
        { text: "Account Name", value: "Name", width: "30%" },
        { text: "Account Number", value: "AcctNum", width: "30%" },
        { text: "Account Type", value: "AccountType", width: "20%" },
        { text: "Current Balance", value: "CurrentBalance", width: "20%" },
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Account Details" }]);
    this.getAccountDetails();
  },
  methods: {
    getAccountDetails() {
      let context = this;
      let api_url = "get-account-details";
      axios({
        method: "get",
        url: api_url,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if(result.data.status == 0){
            Swal.fire("Error", result.data.message, "error");
          }else{
            context.accounts_list = result.data.accounts;
          }
        },
        function() {
          Swal.fire("Error", "Error in fetching accounts details.", "info");
        }
      );
    }
  }
};
</script>
